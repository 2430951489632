// /esign/Merge.jsx
import { Box, Button, Spinner, Text, Image } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import errorAnimation from './error.json';
import successAnimation from './success.json';
import { motion } from "framer-motion";
import Lottie from "lottie-react";
import SignPad from "./SignPad2";
import CustomLink from "../components/CustomLink";
import { MdKeyboardArrowLeft } from "react-icons/md";
import axios from "axios";
import { getMergedSubmitSignature, submitSignature, submitSignatureForLOA } from "./e-signatureService";
import LoadingButton from "../components/LoadingButton";
import { PDFDocument } from "pdf-lib";
import MergedSignPad from "./MergeSignPad";
import { splitMergedPDF } from "./coordinates";
import { removeLastTwoPages } from "../utils/helpers";
const Merge = () => {
    const { envelopeId, envelopeIdForLOA } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [error, setError] = useState(undefined);
    const [docCreate, setDocCreate] = useState({
        isSuccess: false,
        file: undefined,
        message: "Document Signed Successfully",
    })
    const [signData, setSignData] = useState({
        isSuccess: false,
        file: undefined,
        message: "Document saved Successfully",
    });
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfBlob, setPdfBlob] = useState(null);
    const [userName, setUserName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [currentSupplier, setCurrentSupplier] = useState('');
    const [loadingSignature, setLoadingSignature] = useState(false);
    const updateSignData = (data) => {
        setSignData(data);
    };
    useEffect(() => {
        fetchPdf();
    }, []);
    useEffect(() => {
        if (pdfBlob) {
            const objectUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(objectUrl);
            // Clean up object URL when component unmounts
            return () => URL.revokeObjectURL(objectUrl);
        }
    }, [pdfBlob]);
    const fetchPdf = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/merge-pdf/esign/${envelopeId}/${envelopeIdForLOA}`);
            if (response.status === 200) {
                const { contractData, loaData, contentType } = response.data;
                const { title, firstName, middleName, lastName, position } = contractData.contract.otherInformationData['Merchant Details'];
                const { Supplier } = contractData.contract.otherInformationData['Selling Price'];
                // Set user details for signature pad
                const fullNameArray = [title, firstName, middleName, lastName].filter(Boolean);
                setUserName(fullNameArray.join(" "));
                setJobTitle(position);
                setCurrentSupplier(Supplier);
                const merged = await mergedPdf(contractData.pdf, loaData.pdf, Supplier);
                // Convert Uint8Array to Base64 using a safer approach
                function arrayBufferToBase64(buffer) {
                    let binary = "";
                    const bytes = new Uint8Array(buffer);
                    const len = bytes.byteLength;
                    for (let i = 0; i < len; i++) {
                        binary += String.fromCharCode(bytes[i]);
                    }
                    return btoa(binary);
                }
                const mergedBase64 = arrayBufferToBase64(merged);
                // Now use base64ToBlob()
                const mergedPdfBlob = base64ToBlob(mergedBase64, contentType || "application/pdf");
                setPdfBlob(mergedPdfBlob);
            }
            else {
                setError('Failed to retrieve contract PDF');
            }
        } catch (err) {
            console.error('Error fetching contract:', err);
            if (err.response.status === 400) {
                return setError('The contract has already been signed.');
            }
            else if (err.response.status === 404) {
                return setError('Contract not found');
            }
            setError('Unable to fetch contract PDF');
        } finally {
            setLoading(false);
        }
    };
    const base64ToBlob = (base64, contentType) => {
        const byteCharacters = atob(base64);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        return new Blob(byteArrays, { type: contentType });
    };
    const submitSignatureAPI = async (formData1, formData2) => {
        try {
            const [contractResult, loaResult] = await Promise.allSettled([
                submitSignature(formData1, envelopeId),
                submitSignatureForLOA(formData2, envelopeIdForLOA)
            ]);
            if (contractResult.status === "fulfilled" && loaResult.status === "fulfilled") {
                // const mergedResult = await getMergedSubmitSignature(envelopeId, envelopeIdForLOA);
                return {
                    status: 200,
                    file: contractResult.value.data.data.file,
                    message: "Document Signed Successfully"
                };
            } else if (contractResult.status === "fulfilled") {
                return {
                    status: 200,
                    file: contractResult.value.data.data.file,
                    message: "LOA submission failed, but contract submission was successful."
                };
            } else if (loaResult.status === "fulfilled") {
                return {
                    status: 200,
                    file: loaResult.value.data.data.file,
                    message: "Contract submission failed, but LOA submission was successful."
                };
            } else {
                return {
                    status: 400,
                    message: "Both submissions failed!"
                };
            }
        } catch (error) {
            console.error(":siren: Error in API:", error);
            return {
                status: 500,
                message: "Unexpected error occurred during submission."
            };
        }
    };
    const downloadSignedDocument = async () => {
        setLoadingSignature(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/merge-pdf/esign/${envelopeId}/${envelopeIdForLOA}?download=true`);
            const data = await response.json(); // Assuming API returns JSON
            const mergedPdfBuffer = await mergedPdf(data.contractData.pdf, data.loaData.pdf)
            // Convert Buffer array to Uint8Array
            const pdfBuffer = new Uint8Array(mergedPdfBuffer);
            // Convert to Blob
            const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
            // Create URL and trigger download
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const link = document.createElement("a");
            link.href = pdfUrl;
            link.download = `${envelopeId}-${envelopeIdForLOA}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            // Revoke URL to free memory
            URL.revokeObjectURL(pdfUrl);
            setLoadingSignature(false)
        } catch (error) {
            console.error("Error downloading PDF:", error);
            setLoadingSignature(false)
        }
    };
    const handleSubmitSignature = async () => {
        try {
            setLoadingSignature(true)
            const formData1 = new FormData();
            const formData2 = new FormData();
            const signedMergedPdfBuffer = await signData.file.arrayBuffer();
            const { firstPdfBytes, secondPdfBytes } = await splitMergedPDF(signedMergedPdfBuffer)
            formData1.append("file", new Blob([firstPdfBytes], { type: "application/pdf" }));
            formData2.append("file", new Blob([secondPdfBytes], { type: "application/pdf" }));
            const submitResult = await submitSignatureAPI(formData1, formData2)
            if (submitResult.status == 200) {
                setDocCreate({
                    isSuccess: true,
                    file: submitResult.file,
                    message: "Document Signed Successfully",
                });
                setLoadingSignature(false)
            }
            else {
                setError(submitResult.data.message)
            }
        } catch (error) {
            setError("Error during submission");
        }
        setLoadingSignature(false)
    };


    const mergedPdf = async (contractBuffer, loaBuffer, supplier) => {
        let contractBufferFixed = new Uint8Array(contractBuffer.data);
        if (supplier == 'British Gas') {
            contractBufferFixed = await removeLastTwoPages(contractBufferFixed)
        }

        const loaBufferFixed = new Uint8Array(loaBuffer.data);
        try {
            const contractPdf = await PDFDocument.load(contractBufferFixed);
            const loaPdf = await PDFDocument.load(loaBufferFixed);
            const mergedPdf = await PDFDocument.create();
            const contractPages = await mergedPdf.copyPages(contractPdf, contractPdf.getPageIndices());
            contractPages.forEach((page) => mergedPdf.addPage(page));
            const loaPages = await mergedPdf.copyPages(loaPdf, loaPdf.getPageIndices());
            loaPages.forEach((page) => mergedPdf.addPage(page));
            const mergedPdfBuffer = await mergedPdf.save();
            return mergedPdfBuffer;
        } catch (error) {
            console.error(":x: Error merging PDFs:", error);
            return null;
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minH="100vh">
                <Spinner size="lg" />
                <Text fontWeight={600} pl={2} fontSize={20}>Loading...</Text>
            </Box>
        );
    }
    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" flexDirection="row">
                <Box h={'200px'} w={'200px'}>
                    <Lottie animationData={errorAnimation} loop={false} autoplay={true}
                        style={{
                            transform: 'scale(1.5)',
                            transformOrigin: 'center',
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }}
                    />
                </Box>
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ fontWeight: 600, fontSize: 20, marginLeft: "0px", textAlign: "center" }}>
                    {error}
                </motion.div>
            </Box>
        );
    }
    if (docCreate.isSuccess) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minH="100vh" flexDirection="row">
                <Lottie animationData={successAnimation} loop={false} autoplay={true} style={{ height: 300, width: 300 }} />
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} style={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "20px" }} >
                    <Text fontWeight={600} fontSize={20} fontFamily={'Inter'}> {signData.message}</Text>
                    <Button bg={'#069FFF'} _hover={{ bg: '#0695EF', color: '#FFFFFF' }} color={'#FFFFFF'} onClick={downloadSignedDocument} style={{ marginTop: "10px" }} fontFamily={'Inter'} >  {loadingSignature ? <LoadingButton /> : 'Download Signed Document'}</Button>
                    <CustomLink type="basic" to="/" style={{ display: "inline-flex", alignItems: "center", marginTop: "10px" }}>
                        <MdKeyboardArrowLeft fontSize="25px" fontFamily={'Inter'} />
                        Back to Home
                    </CustomLink>
                </motion.div>
            </Box>
        );
    }
    return (
        <Box width="100%" display="grid" placeItems="center" padding="20px">
            <Box width={[300, 600, 900]}>
                {/* Header */}
                <Box display="flex" alignItems="center" gap={2} marginBottom="20px" paddingY="20px" borderBottom="2px solid black">
                    <Image src="/logo.svg" />
                    <Text fontWeight={600}>Crystal Utilities</Text>
                </Box>
                {/* PDF Display */}
                {pdfUrl && (
                    <Box marginTop="50px" marginBottom="20px">
                        <Text fontWeight={600} fontSize={20}>Contract PDF</Text>
                        <iframe src={pdfUrl} width="100%" height="600px" style={{ border: 'none' }} title="Contract PDF" />
                    </Box>
                )}
                {/* Signature Pad */}
                <Box marginTop="50px" marginBottom="20px">
                    <Text fontWeight={600} fontSize={20}>Sign Here</Text>
                    <MergedSignPad code={envelopeId} updateSignData={updateSignData} pdfBlob={pdfBlob} userName={userName} jobTitle={jobTitle} currentSupplier={currentSupplier} />
                </Box>
                {/* Submit Button */}
                {signData.isSuccess && <Box marginTop="20px">
                    <Button
                        onClick={handleSubmitSignature}
                        isDisabled={!signData.isSuccess || loadingSignature}
                        bg={'#069FFF'} _hover={{ bg: '#0695EF', color: '#FFFFFF' }} color={'#FFFFFF'}
                        fontFamily={'Inter'}
                    >
                        {loadingSignature ? <LoadingButton /> : 'Submit Signature'}
                    </Button>
                    {!signData.isSuccess}
                </Box>}
            </Box>
        </Box>
    );
};
export default Merge;