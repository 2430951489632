// /esign/e-signatureService.js
import axios from "axios";

export const submitSignature = async (signatureData, code) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/contract/esign/${code}`,
      signatureData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const submitSignatureForLOA = async (signatureData, code) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/loa/esign/${code}`,
      signatureData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const getMergedSubmitSignature = async (envelopeId, envelopeIdForLOA) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/merge-pdf/esign/${envelopeId}/${envelopeIdForLOA}`);
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};