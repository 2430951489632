import { PDFDocument } from "pdf-lib"
import { capitalizeFirstLetter } from "./menuItems"

export const addQueryParam = (key, value) => {
    const url = new URL(window.location.href)
    url.searchParams.set(key, value)
    window.history.pushState({}, '', url.toString())
}

export const getQueryParam = (key) => {
    const url = new URL(window.location.href)
    return url.searchParams.get(key) || ''
}

export const camalToStr = (str) => {
    return str?.replace(/([a-z])([A-Z])|_/g, '$1 $2')
}


export const wrapText = (text, lineLength = 40) => {
    if ((!text) || (text && text?.length < lineLength)) return text
    let wrappedText = [];
    let currentLine = '';
    const words = text.split(' ');
    for (let word of words) {
        if (currentLine.length + word.length + 1 > lineLength) {
            wrappedText.push(currentLine);
            currentLine = word;
        } else {
            currentLine += (currentLine ? ' ' : '') + word;
        }
    }
    if (currentLine) {
        wrappedText.push(currentLine);
    }
    return <pre style={{ fontFamily: 'Inter', fontWeight: 300, fontSize: 12.3, color: 'red' }}>{wrappedText.join('\n')}</pre>
};

export const exportTitle = 'Export as Excel';
export const removeSpaicialChareter = (inputValue) => inputValue.replace(/^[^a-zA-Z0-9]/, '');

export const numberInput = (inputValue) => inputValue.replace(/[^0-9]/g, '');

export const countryList = [
    "United Kingdom", "India", "United States", "Indonesia", "Nigeria", "Brazil", "Bangladesh",
    "Russia", "Ethiopia", "Mexico", "Japan", "Egypt", "Philippines", "DR Congo", "Vietnam", "Iran", "China", "Pakistan",
    "Turkey", "Germany", "Thailand", "Tanzania", "France", "South Africa", "Italy",
    "Kenya", "Myanmar", "Colombia", "South Korea", "Sudan", "Uganda", "Spain", "Algeria", "Iraq",
    "Argentina", "Afghanistan", "Yemen", "Canada", "Poland", "Morocco", "Angola", "Ukraine", "Uzbekistan",
    "Malaysia", "Mozambique", "Ghana", "Peru", "Saudi Arabia", "Madagascar", "Côte d'Ivoire", "Nepal",
    "Cameroon", "Venezuela", "Niger", "Australia", "North Korea", "Syria", "Mali", "Burkina Faso",
    "Sri Lanka", "Malawi", "Zambia", "Kazakhstan", "Chad", "Chile", "Romania", "Somalia", "Senegal",
    "Guatemala", "Netherlands", "Ecuador", "Cambodia", "Zimbabwe", "Guinea", "Benin", "Rwanda", "Burundi",
    "Bolivia", "Tunisia", "South Sudan", "Haiti", "Belgium", "Jordan", "Dominican Republic", "United Arab Emirates",
    "Cuba", "Honduras", "Czech Republic (Czechia)", "Sweden", "Tajikistan", "Papua New Guinea", "Portugal",
    "Azerbaijan", "Greece", "Hungary", "Togo", "Israel", "Austria", "Belarus", "Switzerland", "Sierra Leone",
    "Laos", "Turkmenistan", "Libya", "Kyrgyzstan", "Paraguay", "Nicaragua", "Bulgaria", "Serbia", "El Salvador",
    "Congo", "Denmark", "Singapore", "Lebanon", "Finland", "Liberia", "Norway", "Slovakia", "State of Palestine",
    "Central African Republic", "Oman", "Ireland", "New Zealand", "Mauritania", "Costa Rica", "Kuwait", "Panama",
    "Croatia", "Georgia", "Eritrea", "Mongolia", "Uruguay", "Bosnia and Herzegovina", "Qatar", "Moldova",
    "Namibia", "Armenia", "Lithuania", "Jamaica", "Albania", "Gambia", "Gabon", "Botswana", "Lesotho",
    "Guinea-Bissau", "Slovenia", "Equatorial Guinea", "Latvia", "North Macedonia", "Bahrain", "Trinidad and Tobago",
    "Timor-Leste", "Estonia", "Cyprus", "Mauritius", "Eswatini", "Djibouti", "Fiji", "Comoros", "Guyana",
    "Solomon Islands", "Bhutan", "Luxembourg", "Montenegro", "Suriname", "Malta", "Maldives", "Micronesia",
    "Cabo Verde", "Brunei", "Belize", "Bahamas", "Iceland", "Vanuatu", "Barbados", "Sao Tome & Principe", "Samoa",
    "Saint Lucia", "Kiribati", "Seychelles", "Grenada", "Tonga", "St. Vincent & Grenadines", "Antigua and Barbuda",
    "Andorra", "Dominica", "Saint Kitts & Nevis", "Liechtenstein", "Monaco", "Marshall Islands", "San Marino",
    "Palau", "Nauru", "Tuvalu", "Holy See"
]

export const isAllValueExist = ({ key1, key2, key3, key4 }) => {

    const Label1 = key1 ? capitalizeFirstLetter(key1) : '';
    const Label2 = key2 ? capitalizeFirstLetter(key2) : '';
    const Label3 = key3 ? capitalizeFirstLetter(key3) : '';
    const Label4 = key4 ? capitalizeFirstLetter(key4) : '';

    const labelParts = [
        Label1,
        Label2,
        Label3,
        Label4
    ].filter(Boolean);

    return labelParts.length > 0
        ? labelParts.join(' ')
        : '--';
}


export const createLableWithOption = ({ value, value2, value3, value4, optionalShow }) => {
    const titleLabel = value ? capitalizeFirstLetter(value) : '';
    const firstNameLabel = value2 ? capitalizeFirstLetter(value2) : '';
    const midNameLabel = value2 ? capitalizeFirstLetter(value3) : '';
    const lastNameLabel = value4 ? capitalizeFirstLetter(value4) : '';
    const optionLabel = capitalizeFirstLetter(optionalShow);

    const labelParts = [
        titleLabel,
        firstNameLabel,
        midNameLabel,
        lastNameLabel
    ].filter(Boolean);

    return labelParts.length > 0
        ? `${labelParts.join(' ')}${optionLabel ? ` (${optionLabel})` : ''}`
        : '';
}

export const removeLastTwoPages = async (pdfBuffer) => {
    try {
        const pdfDoc = await PDFDocument.load(pdfBuffer);
        const totalPages = pdfDoc.getPageCount();
        if (totalPages > 2) {
            pdfDoc.removePage(totalPages - 1);
            pdfDoc.removePage(totalPages - 2);
        } else {
            return pdfBuffer;
        }
        const modifiedPdfBytes = await pdfDoc.save();
        return modifiedPdfBytes;
    } catch (error) {
        return pdfBuffer;
    }
}

export const blobToArrayBuffer = async (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
    });
}