import { Button, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

const CustomButton = ({ children, disabled = false, handler = () => { }, loading = false, type = "primary", style = {}, title = '' }) => {
    const theme = {
        primary: {
            bg: '#069FFF',
            color: '#FFFFFF',
            hover: '#0a95ed',
        },
        secondary: {
            bg: '#A4A4A4',
            color: '#FFFFFF',
            hover: '#898787',
        },
        danger: {
            bg: '#C10404',
            color: '#FFFFFF',
            hover: '#a90303',
        },
    }

    return (
        <Button isDisabled={disabled} onClick={handler} style={style} title={title} fontFamily={'Inter'} color={theme[type].color} boxShadow='md' fontSize={15} fontWeight={600} borderRadius={11} _hover={{ bg: theme[type].hover }} textAlign={'center'} bg={theme[type].bg}>
            {loading ? <><Spinner size={"md"} mr={"10px"} /><Text fontFamily={'Inter'} fontSize={14} fontWeight={500}> Please Wait ... </Text></> : children}
        </Button>
    )
}

export default CustomButton