import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isContractView: 'view',
  meterDetailPayload: {},
  scrollCotractPage: false,
  contractScrollPosition: 0
}

export const contract = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    isContractView: (state, action) => {
      state.isContractView = action?.payload ?? 'view';
    },

    meterDetailPayload: (state, action) => {
      state.meterDetailPayload = action?.payload ?? {};
    },

    scrollCotractPage: (state, action) => {
      state.scrollCotractPage = action?.payload ?? {};
    },

    contractScrollPosition: (state, action) => {
      state.contractScrollPosition = action?.payload ?? {};
    }

  },
});

export const { isContractView, meterDetailPayload, scrollCotractPage, contractScrollPosition } = contract.actions;
export default contract.reducer;


