import { extendTheme } from '@chakra-ui/react';

const breakpoints = {
  sm: '30em', // 480px
  md: '43.75em', // 700px
  lg: '60em', // 960px
  xl: '80em', // 1280px
  xxl: '96em', // 1536px
  xxxl: '120em'
}

const theme = extendTheme({
  breakpoints,
})

export default theme;
