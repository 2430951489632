import { Box, Input, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'signature_pad';
import { PDFDocument, rgb } from 'pdf-lib';
import CustomButton from '../components/CustomButton';
import { toast } from 'react-toastify';
import { getCoordinatesBySupplier } from './coordinates';
const MergedSignPad = ({ pdfBlob, userName, jobTitle, currentSupplier, updateSignData }) => {
    const canvasRef = useRef(null);
    const signaturePad = useRef(null);
    const inputRef = useRef(null);
    const [pdfPreviewUrl, setPdfPreviewUrl] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [warnings, setWarnings] = useState([]);
    useEffect(() => {
        if (canvasRef.current) {
            window.onresize = resizeCanvas;
            resizeCanvas();
            signaturePad.current = new SignaturePad(canvasRef.current, {
                backgroundColor: 'rgba(255, 255, 255, 0)',
                penColor: 'black',
            });
        }
        return () => {
            window.onresize = null;
        };
    }, []);
    const resizeCanvas = () => {
        canvasRef.current.width = Math.min(window.innerWidth - 80, 400);
        canvasRef.current.height = Math.min(window.innerWidth - 80, 400) / 2;
    };
    const handleSignatureChange = (e) => {
        const image = new Image();
        image.src = URL.createObjectURL(e.target.files[0]);
        image.onload = () => {
            signaturePad.current.fromDataURL(image.src);
        };
    };
    const clearSignature = () => {
        signaturePad.current.clear();
        inputRef.current.value = '';
    };
    const saveSignature = async () => {
        if (signaturePad.current.isEmpty()) {
            toast.error("Please draw your signature");
            return;
        }
        const dataURL = signaturePad.current.toDataURL();
        const signingDate = new Date().toLocaleDateString();
        await previewSignatureOnPDF(dataURL, signingDate);
        handleAccordionClick()
    };
    const previewSignatureOnPDF = async (signatureDataURL, signingDate) => {
        try {
            const pdfBytes = await pdfBlob.arrayBuffer();
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const pageCount = pdfDoc.getPageCount();
            // Retrieve all page-specific coordinates for the current supplier
            const supplierCoords = getCoordinatesBySupplier(currentSupplier);
            if (!supplierCoords) {
                setErrorMessage(`Coordinates not found for supplier: ${currentSupplier}`);
                return;
            }
            // Main loop where we apply the signature and text fields dynamically based on page size
            for (let pageIndex = 0; pageIndex < pageCount; pageIndex++) {
                const page = pdfDoc.getPage(pageIndex);
                const { width, height } = page.getSize(); // Get both width and height of the page
                // Last page logic
                const isLastPage = pageIndex === pageCount - 1;
                // Get supplier-specific coordinates for regular pages
                let pageCoords = isLastPage
                    ? { // Fixed coordinates for the last page (2nd PDF)
                        signature: { xPercent: 0.205, yPercent: 0.1, widthPercent: 0.2, heightPercent: 0.05 },
                        signingDate: { xPercent: 0.71, yPercent: 0.12 },
                    }
                    : supplierCoords[pageIndex + 1]; // Get coordinates for the 1-based page index
                if (!pageCoords) {
                    console.warn(`No coordinates set for supplier: ${currentSupplier} on page: ${pageIndex + 1}`);
                    continue;
                }
                // const pageCoords = supplierCoords[pageIndex + 1]; // Get coordinates for the 1-based page index
                // if (!pageCoords) {
                //     console.warn(`No coordinates set for supplier: ${currentSupplier} on page: ${pageIndex + 1}`);
                //     continue; // Skip to the next page if no coordinates found
                // }
                const signatureImageBytes = await fetch(signatureDataURL).then(res => res.arrayBuffer());
                const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
                // Apply signature - Dynamic calculation based on percentage
                page.drawImage(signatureImage, {
                    x: width * pageCoords.signature.xPercent, // Convert percentage to actual pixel value
                    y: height * pageCoords.signature.yPercent, // Convert percentage to actual pixel value
                    width: width * pageCoords.signature.widthPercent, // Convert percentage to actual pixel value
                    height: height * pageCoords.signature.heightPercent, // Convert percentage to actual pixel value
                });
                // Apply userName, jobTitle, and signingDate - Now using the updated structure
                if (pageCoords.userName) {
                    page.drawText(`Name: ${userName}`, {
                        x: width * pageCoords.userName.xPercent, // Dynamic position
                        y: height * pageCoords.userName.yPercent, // Dynamic position
                        size: 12,
                        color: rgb(0, 0, 0),
                    });
                }
                if (pageCoords.jobTitle) {
                    page.drawText(`Job Title: ${jobTitle}`, {
                        x: width * pageCoords.jobTitle.xPercent, // Dynamic position
                        y: height * pageCoords.jobTitle.yPercent, // Dynamic position
                        size: 12,
                        color: rgb(0, 0, 0),
                    });
                }
                if (pageCoords.signingDate) {
                    page.drawText(`${signingDate}`, {
                        x: width * pageCoords.signingDate.xPercent, // Dynamic position
                        y: height * pageCoords.signingDate.yPercent, // Dynamic position
                        size: 12,
                        color: rgb(0, 0, 0),
                    });
                }
            }
            // Save the signed PDF and return as a Blob
            const signedPdfBytes = await pdfDoc.save();
            const signedPdfBlob = new Blob([signedPdfBytes], { type: 'application/pdf' });
            // Pass signed PDF Blob to parent component
            updateSignData({
                isSuccess: true,
                file: signedPdfBlob,
                message: "Document Signed Successfully",
            });
            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            setPdfPreviewUrl(pdfDataUri);
        } catch (error) {
            console.error("Failed to preview signature on PDF", error);
        }
    };
    const handleAccordionClick = (e) => {
        setTimeout(function () {
            window.scrollBy({
                top: 400,
                behavior: 'smooth'
            });
        }, 10);
    };
    return (
        <>
            {errorMessage && (
                <div style={{ color: 'red', marginBottom: '10px' }}>
                    {errorMessage}
                </div>
            )}
            {warnings.length > 0 && (
                <div style={{ color: 'orange', marginBottom: '10px' }}>
                    {warnings.map((warning, index) => (
                        <p key={index}>{warning}</p>
                    ))}
                </div>
            )}
            <canvas ref={canvasRef} style={{ border: '1px solid black', padding: '2px' }} width={400} height={200}></canvas>
            <Text marginTop={"10px"}>or, upload your signature image:</Text>
            <Input
                ref={inputRef}
                type={"file"}
                accept={"image/*"}
                maxWidth={400}
                padding={'4px'}
                marginTop={"10px"}
                onChange={handleSignatureChange}
            />
            <Box
                marginTop={"10px"}
                marginBottom={'80px'}
                maxW={400}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <CustomButton handler={clearSignature} type='secondary'>Clear</CustomButton>
                <CustomButton handler={saveSignature} type='primary'>Save Signature</CustomButton>
            </Box>
            {pdfPreviewUrl && (
                <iframe
                    src={pdfPreviewUrl}
                    title="PDF Preview"
                    width="100%"
                    height="600px"
                    style={{ border: '1px solid #ccc', marginTop: '20px' }}
                />
            )}
        </>
    );
};
export default MergedSignPad;

